<template>
  <section>
    <div
      class="headbanner"
      :style="`background-image:url(${bannerUrl});`"
    ></div>
    <v-container class="container-custom">
      <div class="boxinside">
        <h1>How Necojobs Works For You</h1>
        <p>
          Neco Jobs is a leading functional platform that connects you with your
          dream job and that too with just a single click away. It believes in
          ‘like what you do, where you do it and get paid more’. Since its
          inception in the year 2075, Neco Jobs is rapidly growing as one of the
          best job portals in Nepal. It is becoming the easiest and most
          reliable medium where a wide array of institutions meet with the
          professionals who would meet every requirement in their respective
          fields.<br /><br />
          Specializing in permanent staffing, the placement services here at
          Neco Jobs include multiple organizations in diversified fields like IT
          & Telecommunication, financial institutions, corporate houses, trading
          companies, NGO/INGOs, etc. <br /><br />
          Even with these varieties of options, Neco makes sure that you can
          search and apply for the job of your priority with optional ease. It
          provides innovative solutions focusing on the hassle-free application
          for job seekers and organized recruiting for institutions. And it is
          proud to introduce itself as the recruitment service provider that
          offers full free services to all the job seekers.<br /><br />
          Thus, if you are looking for a perfect job or choicest job candidates,
          get in touch with the Neco family today. It is the right platform
          where job seekers get access to numerous vacancies for free and work
          for the reputed companies they opt for. Likewise, for the recruiters,
          they can fill their void of professional requirements with skilled and
          trained manpower. Neco Jobs also provides its competent services as an
          HR Consulting and Manpower Outsourcing. As a legally certified HR
          firm, it offers strategic HRM services not only through recruitment
          but also via training, consulting, and outsourcing. As the aspiring
          strategic management partner, Neco Jobs is determined to emphasize the
          dynamic growth of both people and organizations. How Neco Jobs Works
          for you <br /><br />
          Neco Jobs Works in a fast, reliable, and most efficient way such that
          our job seekers and recruiters will face less hassle. In your journey
          to meet your dream job, Neco has 3 simple ways to function:
        </p>

        <v-row>
          <v-col md="4">
            <div class="about3col">
              <img src="@/assets/search.png" alt="" />
              <h4>Find Your Job</h4>
              <p>
                We have a wide array of job options that consists of several
                institutional backgrounds. Find a job from those options that
                are suitable for you and looking for someone like you
              </p>
            </div>
          </v-col>
          <v-col md="4">
            <div class="about3col">
              <img src="@/assets/apply.png" alt="" />
              <h4>Apply</h4>
              <p>
                Apply to numerous offers to find the best job you have been
                dreaming of and that would uplift your career
              </p>
            </div>
          </v-col>
          <v-col md="4">
            <div class="about3col">
              <img src="@/assets/gethired.png" alt="" />
              <h4>Get Hired</h4>
              <p>
                Get along with your office culture, start working fluently and
                do what’s best for your Career!
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "About",
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
  }),
  methods: {
    ...mapActions(["getAssets", "getSingleMetaPage"]),
  },
  computed: {
    ...mapGetters(["allAssets", "allMeta"]),
  },

  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
    };
  },
  async created() {
    await this.getAssets({
      title: "AboutUsBanner",
      connectedTo: "page",
    });
    this.bannerUrl = this.allAssets[0].image;
    await this.getSingleMetaPage("about");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.headbanner {
  background-size: cover;
  height: 450px;
  width: 100%;
  background-position-x: center;
}

.boxinside {
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  background: $white;
  margin-bottom: 20px;
  padding: 20px;
  background: $white;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 30px !important;
  &:last-of-type {
    margin-bottom: 0;
  }

  h1 {
    font-size: $titlefont;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  p {
    font-size: $normal_lfont;
    color: $paragraphcolor;
  }
}

.about3col {
  text-align: center;
  margin-top: 20px;
  border-radius: 5px;
  padding: 35px 12px;
  box-shadow: 0px 1px 10px 1px rgb(236, 236, 236);
  min-height: 300px;
  margin-bottom: 30px;
  img {
  }
  h4 {
    color: $mainfooterback;
    font-size: $titlefonth4;
    padding: 5px 0 10px;
  }
  p {
    font-size: $normal_lfont;
  }
}

@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .headbanner {
    height: 23vh;
    width: 100%;
    background-position: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headbanner {
    height: 30vh;
    width: 100%;
    background-position: center;
  }
}
</style>
